import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          The future belongs to the bold.
        </p>
        <p>
        <iframe frameborder="0" width="220" height="140" src="https://www.mql5.com/en/signals/widget/signal/6uer"></iframe>
        </p>
        <p>
          Learn to trade and be financially free for ever
        </p>
      </header>
    </div>
  );
}

export default App;
